import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Styles from "../css_modules/ProjectList.module.css"
import Banner from "../common/Banner.js"

function AdminProjectList() {
    
    return (
        <div className={Styles.projectListBody}>
            <h1>Liste des projets</h1>
            <ProjectListItems />
        </div>
    )
}

function ProjectListItems() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await fetch(process.env.REACT_APP_API_URL+'/api/projects/list');
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setProjects(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
        
          fetchData();
    }, []);

    return (
          <ul className={Styles.projectList}>
            {projects.map((project) => (
                <ProjectListItem project={project}/>
            ))}
          </ul>
    );
}

function ProjectListItem({project}) {
    return (
                        
        <li key={project.project_uuid} className={Styles.project}>
            <Link to={"/project/"+project.project_uuid}>
                <div className={Styles.banner}>
                    <Banner url={project.banner} />
                </div>
                <div className={Styles.informations}>
                    <span>{project.summary}</span>
                </div>
            </Link>
        </li>
)
}

export default AdminProjectList;