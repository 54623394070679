import React, { useContext } from 'react';
import { SiteSideContext } from '../contexts/SiteSideContext';
import { PageContext } from '../contexts/PageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import Styles from "../css_modules/About.module.css"
import { Link } from 'react-router-dom';

function About() {
    const {setCurrentPage} = useContext(PageContext);
    const {setCurrentSiteSide} = useContext(SiteSideContext);
    setCurrentSiteSide("home");
    setCurrentPage("about");

    function changeCurrentPageSide(page, sideSite) {
        setCurrentPage(page);
        setCurrentSiteSide(sideSite);
    }

    const contacts = [
        {key:"linkedin", text:"LinkedIn", icon:<FontAwesomeIcon icon={faLinkedin} />, link:"https://www.linkedin.com/company/darktouffe"},
        {key:"instagram", text:"Instagram", icon:<FontAwesomeIcon icon={faInstagram}/>, link:"https://www.instagram.com/darktouffe_company/"},
        {key:"facebook", text:"Facebook", icon:<FontAwesomeIcon icon={faFacebook} />, link:"https://www.facebook.com/DarkTouffe"},
      ]

    return (
        <div className={Styles.aboutPage}>
            <h1>A propos</h1>
            <div className={Styles.aboutBody}>
                <div className={Styles.aboutImg}>
                    <img src="profil-cercle.png"/>
                    <img src="darktouffe-cercle.png"/>
                </div>
                <h2>Français</h2>
                <div className={Styles.aboutPart}>
                    <p className={Styles.firstLine}><span className={Styles.hello}>Bienvenu</span> à vous!</p>
                    <br/>
                    <p>Je m'appelle Anthony CONTREVILLIERS, je suis un jeune développeur informatique.</p>
                    <p><span className={Styles.relevant}>"Je suis passionnée par les nouvelles technologies en général"</span>, cette phrase semble clichée cependant c'est réellement mon cas.</p>
                    <p>Du développement de <span className={Styles.relevant}>site web</span> et de <span className={Styles.relevant}>logiciel</span> au développement de <span className={Styles.relevant}>jeu vidéo</span>, en passant par la <span className={Styles.relevant}>réalité augmentée</span>, j'explore tous les sujets qui me passionnent.</p>
                    <p>C'est dans ce but que j'ai crée le site web Darktouffe.</p>
                    <br/>
                    <p>Darktouffe sera la vitrine des projets que je réalise, ils seront accessibles depuis la page des <Link to="/project" onClick={() => changeCurrentPageSide("project", "home")}>projets</Link>.</p>
                    <p>Je vous rédigerai de même des articles sur divers sujets liés de prêt ou de loin à l'informatique, ils seront disponible sur la page des <Link to="/blog" onClick={() => changeCurrentPageSide("blog", "home")}>articles</Link>.</p>
                    <p><Link to="/" onClick={() => changeCurrentPageSide("home", "home")}>En route astronaute!</Link ></p>
                </div>
                <h2>English</h2>
                <div className={Styles.aboutPart}>
                    <p className={Styles.firstLine}><span className={Styles.hello}>Welcome</span> to you!</p>
                    <br/>
                    <p>My name is Anthony CONTREVILLIERS, and I'm a young IT developer.</p>
                    <p><span className={Styles.relevant}>"New technologies are my passion"</span>, sounds cliché, but it's really true for me.</p>
                    <p>From <span className={Styles.relevant}>website</span> and <span className={Styles.relevant}>software</span> development to <span className={Styles.relevant}>video game</span>, and even<span className={Styles.relevant}>augmented reality</span>, I explore all the subjects that fascinate me.</p>
                    <p>That's why I've created the Darktouffe website.</p>
                    <br/>
                    <p>Darktouffe will be a showcase for the projects I'm working on, which will be accessible from the <Link to="/project" onClick={() => changeCurrentPageSide("project", "home")}>projets</Link> page.</p>
                    <p>I'll also be writing articles on various computer-related subjects, which will be available on the <Link to="/blog" onClick={() => changeCurrentPageSide("blog", "home")}>articles</Link> page.</p>
                    <p><Link to="/" onClick={() => changeCurrentPageSide("home", "home")}>Let's go astronaut!</Link></p>
                </div>
                <h2>Español</h2>
                <div className={Styles.aboutPart}>
                    <p className={Styles.firstLine}><span className={Styles.hello}>¡Bienvenido!</span></p>
                    <br/>
                    <p>Me llamo Anthony CONTREVILLIERS y soy un joven desarrollador informático.</p>
                    <p><span className={Styles.relevant}>" A mi me gustan las nuevas tecnologías en general"</span>, suena a tópico, pero realmente es así para mi.</p>
                    <p>Desde el desarrollo de <span className={Styles.relevant}>sitios web</span> y <span className={Styles.relevant}>software</span> hasta el desarrollo de <span className={Styles.relevant}>videojuegos</span>, y la <span className={Styles.relevant}>realidad aumentada</span>, exploro todos los temas que me fascinan.</p>
                    <p>Por eso he creado el sitio web Darktouffe.</p>
                    <br/>
                    <p>Darktouffe será un escaparate de los proyectos en los que estoy trabajando, a los que se podrá acceder desde la página de <Link to="/project" onClick={() => changeCurrentPageSide("project", "home")}>proyectos</Link>.</p>
                    <p>También escribiré artículos sobre diversos temas relacionados de algún modo con la informática, que estarán disponibles en la página de <Link to="/blog" onClick={() => changeCurrentPageSide("blog", "home")}>artículos</Link>.</p>
                    <p><Link to="/" onClick={() => changeCurrentPageSide("home", "home")}>¡Vamos astronauta!</Link></p>
                </div>
            </div>

        </div>
    )
}

export default About;