import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { SiteSideContext } from '../contexts/SiteSideContext';
import Styles from '../css_modules/Admin.module.css'

function AdminBase() {
    const {setCurrentSiteSide} = useContext(SiteSideContext);
    setCurrentSiteSide("admin");
    return (
        <div className={Styles.adminBody}>
            <Outlet/>
        </div>
    )
}

export default AdminBase;