import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { SiteSideContext } from '../contexts/SiteSideContext';

function Base() {
    const {setCurrentSiteSide} = useContext(SiteSideContext);
    setCurrentSiteSide("home");
    return (
        <Outlet/>
    )
}

export default Base;