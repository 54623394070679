import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {validURL} from '../Utils.js'
import Styles from "../css_modules/ArticleManager.module.css"

const Medium = lazy(() => import('../common/Medium.js'));
function ArticleManager() {
    let params = useParams()
    if (Object.keys(params).length === 0){
        return (
            <div className={Styles.ArticleManagerBody}>
                <h1>Ajouter un article</h1>
                <ArticleForm/>
            </div>
        );
    }
    else {
        return (
            <div className={Styles.ArticleManagerBody}>
                <h1>Modifier l'article</h1>
                <ArticleForm article_id={params.idArticle}/>
            </div>
            
        );
    }
}

function ArticleForm({article_id}) {
    const [categories, setCategoriess] = useState([]);
    const [article, setArticle] = useState({
        pk:"",
        name: "",
        summary: "",
        banner: "",
        description: "",
        medium: "",
        category: "",
      });

    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accesToken'),
        }
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/api/articles/categories', requestOptions)
          .then((response) => response.json())
          .then((data) => setCategoriess(data))
          .catch((error) => console.error('Error fetching data:', error));
      }, []);

    useEffect(() => {
        if (article_id !== undefined) {
            fetch(process.env.REACT_APP_API_URL+'/api/articles/' + article_id, requestOptions)
            .then((response) => response.json())
            .then((data) => setArticle(data[0]))
            .catch((error) => console.error('Error fetching data:', error));
        }
        else {
            setArticle({
                pk:"",
                name: "",
                summary: "",
                banner: "",
                description: "",
                medium: "",
                category: "",
            });
        }
    }, [article_id]);

    function handleSubmit(event){
        event.preventDefault();

        // Get form media values
        const form = event.target;
        const banner = form.elements.banner.files[0]; // For file input
        const medium = form.elements.medium.files[0]; // For file input

        article.banner = banner !== undefined ? banner : "";
        article.medium = medium !== undefined ? medium : "";

        let url = '';
        if (article_id !== undefined) {
            url = process.env.REACT_APP_API_URL+`/api/articles/update`;
        }
        else {
            url = process.env.REACT_APP_API_URL+`/api/articles/create`;
        }
        axios.post(url, article, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('accesToken')
            }
          })
          .then(res => {
            console.log(res);
            console.log(res.data);
            window.location.reload();
        })
        .catch((error) => console.error('Error while attempting to create or update an article:', error));
    }

    return (
        <form onSubmit={handleSubmit}>
            <input type="hidden" value={article.pk}/>
            <div>
                <label>nom de l'article</label>
                <input type="text" name="article_name" value={article.name} onChange={(e) => setArticle({ ...article, name: e.target.value })} required/>
            </div>
            <div>
                <label> résumé </label>
                <input type="text" name="summary" value={article.summary} onChange={(e) => setArticle({ ...article, summary: e.target.value })} required/>
            </div>
            <div>
                <label> bannière </label>
                <div className={Styles.ArticleManagerInputFile}>
                    <input type="file" name="banner"/>
                    {article.banner && validURL(article.banner) && (
                        <div>
                            <img src={article.banner} alt="Article Banner" />
                        </div>
                    )}
                </div>
            </div>
            <div>
                <label> description </label>
                <textarea name="description" value={article.description} onChange={(e) => setArticle({ ...article, description: e.target.value })} required></textarea>
            </div>
            <div>
                <label> medium </label>
                <div className={Styles.ArticleManagerInputFile}>
                    <input type="file" name="medium"/>
                    {article.medium && validURL(article.medium) &&  (
                         <Suspense fallback={<div>Loading…</div>}>
                            <Medium url={article.medium} />
                         </Suspense>
                    )}
                </div>
            </div>
            <div>
                <label> categorie </label>
                <select name="category" value={article.category} onChange={(e) => setArticle({ ...article, category: e.target.value })} required>
                    <option value=""> -- select an option -- </option>
                    {categories.map((category) => 
                        <option key={category.pk} name={category.name+" category"} value={category.pk}>{category.name}</option>
                    )}
                </select>
            </div>
            <div className={Styles.ButtonDiv}>
                {article_id !== undefined ? <button type="submit">Modifier</button> : <button type="submit">Créer</button>}
                
            </div>
        </form>
    );
}

export default ArticleManager;