import React, { createContext, useState, } from "react";
import jwtDecode from 'jwt-decode';

const UserContext = createContext();

const UserContextProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(() => (localStorage.getItem('accesToken') ? jwtDecode(localStorage.getItem('accesToken')) : null));

    return (
        <UserContext.Provider value={{currentUser, setCurrentUser}}>
            {children}
        </UserContext.Provider>
    );
}

export {UserContext, UserContextProvider}