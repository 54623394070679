import React, { createContext, useState, } from "react";

const SiteSideContext = createContext();

const SiteSideContextProvider = ({children}) => {
    const [currentSiteSide, setCurrentSiteSide] = useState("home");

    return (
        <SiteSideContext.Provider value={{currentSiteSide, setCurrentSiteSide}}>
            {children}
        </SiteSideContext.Provider>
    );
}

export {SiteSideContext, SiteSideContextProvider}