import React, { useContext } from 'react';
import { PageContext } from '../contexts/PageContext';

function AdminHome() {
    const {setCurrentPage} = useContext(PageContext);
    setCurrentPage("administration");
    return (
        <h1>Bienvenue sur la page d'Administration</h1>
    )
}

export default AdminHome;