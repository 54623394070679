import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode';
import { UserContext } from '../contexts/UserContext';


const TokenContext = createContext();

const TokenContextProvider = ({children}) => {
    const [currentToken, setCurrentToken] = useState("");
    const {setCurrentUser} = useContext(UserContext);
    let [loading, setLoading] = useState(true)
    let navigate = useNavigate();
    
    let logoutUser = () => {
        localStorage.removeItem('accesToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('token');
        setCurrentToken(null);
        setCurrentToken(null);
        navigate('/authentication');
        window.location.reload();
    }

    const updateToken = async () => {

        const response = await fetch(process.env.REACT_APP_API_URL+'/api/token/refresh/', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body:localStorage.getItem('token')
        })
       
        const data = await response.json()
        if (response.status === 200) {
            localStorage.setItem('accesToken', data.access);
            localStorage.setItem('refreshToken', JSON.stringify(data.refresh));
            localStorage.setItem('token', JSON.stringify(data));
            setCurrentToken(data)
            setCurrentUser(jwtDecode(data.access));
        } //else {
        //     logoutUser()
        // }
    }


    useEffect(()=>{
        if(loading){
            setLoading(false);
            updateToken();
        }

        const REFRESH_INTERVAL = 1000 * 60 * 4 // 4 minutes
        let interval = setInterval(()=>{
            if(localStorage.getItem('token')){
                updateToken()
            }
        }, REFRESH_INTERVAL)
        return () => clearInterval(interval)

    },[]);

    return (
        <TokenContext.Provider value={{currentToken, setCurrentToken, logoutUser}}>
            {children}
        </TokenContext.Provider>
    );
}

export {TokenContext, TokenContextProvider}