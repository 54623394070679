import React, { useState, useEffect, lazy, Suspense} from 'react';
import { useParams } from 'react-router-dom';
import Stlyes from "../css_modules/Article.module.css"
import { Ornement } from "../Ornement/Ornement.js"

const Medium = lazy(() => import('../common/Medium.js'));
function Article() {
    let params = useParams()
    return (
        <div className={Stlyes.articlePage}>
            <ArticleContent article_uuid={params.idArticle} />
        </div>
    );
}

function ArticleContent({article_uuid}) {
    const [article, setArticle] = useState({
        pk:"",
        name: "",
        summary: "",
        banner: "",
        description: "",
        medium: "",
        technology: "",
    });
    const [articleDescription, setArticleDescription] = useState('');

    useEffect(() => {
        if (article_uuid !== undefined) {
            fetch(process.env.REACT_APP_API_URL+'/api/articles/' + article_uuid)
            .then((response) => response.json())
            .then((data) => {
                setArticleDescription(Ornement(data[0].description));
                setArticle(data[0]);
            })
            .catch((error) => console.error('Error fetching data:', error));
        }
    }, []);

    return (
        <>
            <h1>{article.name}</h1>
            <div className={Stlyes.articleBody} dangerouslySetInnerHTML={{__html: articleDescription}}/>
            <div className={Stlyes.articleMendium}>                      
                <Suspense fallback={<div>Loading…</div>}>
                    <Medium url={article.medium} />
                </Suspense>
            </div>
        </>
    )
}

export default Article;