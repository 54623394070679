import React, { useContext } from 'react';
import { SiteSideContext } from '../contexts/SiteSideContext';
import { PageContext } from '../contexts/PageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faWheatAwn, faC, faF, faS } from '@fortawesome/free-solid-svg-icons';
import Styles from "../css_modules/Contact.module.css"

function Contact() {
    const {setCurrentPage} = useContext(PageContext);
    const {setCurrentSiteSide} = useContext(SiteSideContext);
    setCurrentSiteSide("home");
    setCurrentPage("contact");

    const contacts = [
        {key:"linkedin", text:"LinkedIn", icon:<FontAwesomeIcon icon={faLinkedin} />, link:"https://www.linkedin.com/company/darktouffe"},
        {key:"instagram", text:"Instagram", icon:<FontAwesomeIcon icon={faInstagram}/>, link:"https://www.instagram.com/darktouffe_company/"},
        {key:"facebook", text:"Facebook", icon:<FontAwesomeIcon icon={faFacebook} />, link:"https://www.facebook.com/DarkTouffe"},
        {key:"malt", text:"Malt", icon:<FontAwesomeIcon icon={faWheatAwn} />, link:"https://www.malt.fr/profile/anthonycontrevilliers"},
        {key:"comeup", text:"Come Up", icon:<FontAwesomeIcon icon={faC} />, link:"https://comeup.com/fr/@darktouffe-co"},
        {key:"fiverr", text:"Fiverr", icon:<FontAwesomeIcon icon={faF} />, link:"https://fr.fiverr.com/darktouffe"},
        {key:"superprof", text:"Superprof ", icon:<FontAwesomeIcon icon={faS} />, link:"https://www.superprof.fr/diplome-master-ingenierie-jeu-video-competence-developpement-web.html"},
      ]

    return (
        <div className={Styles.contactBody}>
            <h1>Contacts</h1>
            <ul className={Styles.contactMenu}>
                {contacts.map((contact) => (
                    <li className={`${Styles.contactMenuItem} ${Styles[contact.key]}`}>
                        <a href={contact.link}>{contact.icon}<span>{contact.text}</span></a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Contact;