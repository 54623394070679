import React from "react";
import Styles from "../css_modules/Popup.module.css"

function Popup({ title, validText, cancelText, callbackValid, callbackCancel}){
    return (
      <div className={Styles.popupContainer}>
        <div className={Styles.popupBody}>
          <h1>{title}</h1>
          <div className={Styles.popupButtonsDiv}>
            <button className={Styles.popupButton} onClick={callbackValid}>{validText}</button>
            <button className={Styles.popupButton} onClick={callbackCancel}>{cancelText}</button>
          </div>
        </div>
      </div>
    );
  };

export default Popup;