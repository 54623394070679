import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { validUserToken } from '../Utils.js'

const PrivateRoute = ({children, ...rest}) => {
    const {currentUser} = useContext(UserContext);

    return !validUserToken(currentUser) ? <Navigate to='/authentication'/> : children;
}

export default PrivateRoute;