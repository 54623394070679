import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Styles from "../css_modules/Authentication.module.css";
import axios from 'axios'
import jwtDecode from 'jwt-decode';
import { UserContext } from '../contexts/UserContext';
import { TokenContext } from '../contexts/TokenContext';

function Authentication() {
    const [badCredentials, setBadCredentials] = useState(false);

    return (
        <div className={Styles.loginBody}>
            <h1>Connexion au site web Darktouffe</h1>
            {badCredentials ? <p className={Styles.badCredentials}>Mauvais identifiants</p> : null}
            <div className={Styles.loginFormDiv}>
                <LoginForm setBadCredentials={setBadCredentials}/>
            </div>
        </div>
    );
}

function LoginForm({setBadCredentials}) {
    const [credentials, setCredentials] = useState({username: "", password: ""});
    const {setCurrentUser} = useContext(UserContext);
    const {setCurrentToken} = useContext(TokenContext);

    const navigate = useNavigate()

    function handleChangeName(event) {
        setCredentials({username: event.target.value, password: credentials.password});    
    }
    
    function handleChangePassword(event) {
        setCredentials({username: credentials.username, password: event.target.value});
    }

    function handleSubmit(event){
        event.preventDefault();
        console.log(credentials)
        axios.post(process.env.REACT_APP_API_URL+`/api/token/`, credentials, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(res => {
            console.log(res);
            console.log(res.data);

            if(res.data) {
                localStorage.setItem('accesToken', res.data.access);
                localStorage.setItem('refreshToken', JSON.stringify(res.data.refresh));
                localStorage.setItem('token', JSON.stringify(res.data));
                setCurrentToken(res.data)
                setCurrentUser(jwtDecode(res.data.access));
                navigate('/');
            }
        }).catch(error => {
            console.error('Error deleting resource:', error);
            setBadCredentials(true);
        });
    }

    return (
        <form onSubmit={handleSubmit} className={Styles.loginForm}>
            <div>
                <label className={Styles.loginLabel}>nom ou email</label>
                <input className={Styles.loginInput} type="text" name="name" onChange={handleChangeName}></input>
            </div>
            <div>
                <label className={Styles.loginLabel}> mot de passe</label>
                <input className={Styles.loginInput} type="password" name="password" onChange={handleChangePassword}></input>
            </div>
            <button type="submit">Connexion</button>
        </form>
    );
}

export default Authentication;