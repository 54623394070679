import React, { useState, useEffect, lazy, Suspense} from 'react';
import { useParams } from 'react-router-dom';
import Stlyes from "../css_modules/Project.module.css"
import { Ornement } from "../Ornement/Ornement.js"

const Medium = lazy(() => import('../common/Medium.js'));
function Project() {
    let params = useParams()
    return (
        <div className={Stlyes.projectPage}>
            <ProjectContent project_uuid={params.idProject} />
        </div>
    );
}

function ProjectContent({project_uuid}) {
    const [project, setProject] = useState({
        pk:"",
        name: "",
        summary: "",
        banner: "",
        description: "",
        medium: "",
        technology: "",
    });
    const [projectDescription, setProjectDescription] = useState('');

    useEffect(() => {
        if (project_uuid !== undefined) {
            fetch(process.env.REACT_APP_API_URL+'/api/projects/' + project_uuid)
            .then((response) => response.json())
            .then((data) => {
                setProjectDescription(Ornement(data[0].description));
                setProject(data[0]);
            })
            .catch((error) => console.error('Error fetching data:', error));
        }
    }, []);

    return (
        <>
            <h1>{project.name}</h1>
            <div className={Stlyes.projectBody} dangerouslySetInnerHTML={{__html: projectDescription}}/>
            <div className={Stlyes.projectMendium}>                      
                <Suspense fallback={<div>Loading…</div>}>
                    <Medium url={project.medium} />
                </Suspense>
            </div>
        </>
    )
}

export default Project;