import React, { createContext, useState, } from "react";

const PageContext = createContext();

const PageContextProvider = ({children}) => {
    const [currentPage, setCurrentPage] = useState("home");

    return (
        <PageContext.Provider value={{currentPage, setCurrentPage}}>
            {children}
        </PageContext.Provider>
    );
}

export {PageContext, PageContextProvider}