import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import ProjectList from './ProjectList'; // Adjust the path as needed
import Menu from './common/Menu';
import Base from './common/Base';
import Home from './home/Home';
import Projects from './home/Projects'
import ProjectList from './home/ProjectList'
import Project from './home/Project'
import Blog from './home/Blog'
import Contact from './home/Contact'
import ArticleList from './home/ArticleList'
import Article from './home/Article'
import Authentication from './common/Authentication';
import AdminBase from './admin/AdminBase';
import AdminHome from './admin/AdminHome';
import AdminProjects from './admin/AdminProjects';
import AdminProjectList from './admin/AdminProjectList';
import ProjectManager from './admin/ProjectManager';
import AdminArticles from './admin/AdminArticles';
import AdminArticleList from './admin/AdminArticleList';
import ArticleManager from './admin/ArticleManager';
import AdminMedia from './admin/AdminMedia';
import AdminMediumList from './admin/AdminMediumList';
import MediumManager from './admin/MediumManager';
import PrivateRoute from './common/PrivateRoute'
import { SiteSideContext } from './contexts/SiteSideContext';
import { UserContextProvider } from './contexts/UserContext';
import { TokenContextProvider } from './contexts/TokenContext';
import Styles from "./css_modules/App.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faPlay, faPenNib, faEnvelope, faScrewdriverWrench, faUserAstronaut, faBook } from '@fortawesome/free-solid-svg-icons';
import About from './home/About';

function App() {
  const {currentSiteSide} = useContext(SiteSideContext);

  const pagesLinksAdmin = [
    {key:"administration", text:"Accueil", icon:<FontAwesomeIcon icon={faScrewdriverWrench} />},
    {key:"project", text:"Projets", icon:<FontAwesomeIcon icon={faPlay} />},
    {key:"article", text:"Articles", icon:<FontAwesomeIcon icon={faPenNib} />},
    {key:"media", text:"Médiatheque", icon:<FontAwesomeIcon icon={faBook} />},
    {key:"home", text:"Site Web", icon:<FontAwesomeIcon icon={faHouse} />}
  ]
  const pagesLinks = [
    {key:"home", text:"Accueil", icon:<FontAwesomeIcon icon={faHouse} />},
    {key:"project", text:"Projets", icon:<FontAwesomeIcon icon={faPlay} />},
    {key:"blog", text:"Blog", icon:<FontAwesomeIcon icon={faPenNib} />},
    {key:"about", text:"About", icon:<FontAwesomeIcon icon={faUserAstronaut} />},
    {key:"contact", text:"Contact", icon:<FontAwesomeIcon icon={faEnvelope} />},
    {key:"administration", text:"Administration", icon:<FontAwesomeIcon icon={faScrewdriverWrench} />}
  ]

  return (
    <div className={Styles.App}>
      <BrowserRouter>
        <UserContextProvider>
          <TokenContextProvider>
            {/* menu for the front office */}
            {currentSiteSide === "home" ? <Menu pagesLinks={pagesLinks} /> : null}
            {/* menu for the back office */}
            {currentSiteSide === "admin" ? <Menu pagesLinks={pagesLinksAdmin} /> : null}
            <Routes>
              {/* home page */}
              <Route path="/" element={<Base />}>
                <Route index element={<Home pagesLinks={pagesLinks} />} />
                <Route path="project" element={<Projects />}>
                  <Route index element={<ProjectList />} />
                  <Route path=":idProject" element={<Project />} />
                </Route>
                <Route path="blog" element={<Blog />}>
                  <Route index element={<ArticleList />} />
                  <Route path=":idArticle" element={<Article />} />
                </Route>
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                {/* admin home page */}
                <Route path="admin" element={<PrivateRoute><AdminBase /></PrivateRoute>}>
                    {/* admin Hhome page */}
                    <Route index element={<AdminHome />} />
                    {/* admin project page */}
                    <Route path="project" element={<AdminProjects />}>
                      <Route index element={<AdminProjectList />} />
                      <Route path="create" element={<ProjectManager/>}/>
                      <Route path=":idProject" element={<ProjectManager/>}/>
                    </Route>
                    <Route path="article" element={<AdminArticles />}>
                      <Route index element={<AdminArticleList />} />
                      <Route path="create" element={<ArticleManager/>}/>
                      <Route path=":idArticle" element={<ArticleManager/>}/>
                    </Route>
                    <Route path="media" element={<AdminMedia />}>
                      <Route index element={<AdminMediumList />} />
                      <Route path="create" element={<MediumManager/>}/>
                      <Route path=":idMedium" element={<MediumManager/>}/>
                    </Route>
                </Route>
              </Route>
              {/* connexion page */}
              <Route path="/authentication" element={<Authentication />}/>
            </Routes>
          </TokenContextProvider>
        </UserContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
