import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {validURL} from '../Utils.js'
import Styles from "../css_modules/ProjectManager.module.css"

const Medium = lazy(() => import('../common/Medium.js'));
function ProjectManager() {
    let params = useParams()
    if (Object.keys(params).length === 0){
        return (
            <div className={Styles.ProjectManagerBody}>
                <h1>Ajouter un projet</h1>
                <ProjectForm/>
            </div>
        );
    }
    else {
        return (
            <div className={Styles.ProjectManagerBody}>
                <h1>Modifier le projet</h1>
                <ProjectForm project_id={params.idProject}/>
            </div>
            
        );
    }
}

function ProjectForm({project_id}) {
    const [technologies, setTechnologies] = useState([]);
    const [project, setProject] = useState({
        pk:"",
        name: "",
        summary: "",
        banner: "",
        description: "",
        medium: "",
        technology: "",
      });

    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accesToken'),
        }
    };

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL+'/api/projects/technologies', requestOptions)
          .then((response) => response.json())
          .then((data) => setTechnologies(data))
          .catch((error) => console.error('Error fetching data:', error));
      }, []);

    useEffect(() => {
        if (project_id !== undefined) {
            fetch(process.env.REACT_APP_API_URL+'/api/projects/' + project_id, requestOptions)
            .then((response) => response.json())
            .then((data) => setProject(data[0]))
            .catch((error) => console.error('Error fetching data:', error));
        }
        else {
            setProject({
                pk:"",
                name: "",
                summary: "",
                banner: "",
                description: "",
                medium: "",
                technology: "",
            });
        }
    }, [project_id]);

    function handleSubmit(event){
        event.preventDefault();

        // Get form media values
        const form = event.target;
        const banner = form.elements.banner.files[0]; // For file input
        const medium = form.elements.medium.files[0]; // For file input

        project.banner = banner !== undefined ? banner : "";
        project.medium = medium !== undefined ? medium : "";

        let url = '';
        if (project_id !== undefined) {
            url = process.env.REACT_APP_API_URL+`/api/projects/update`;
        }
        else {
            url = process.env.REACT_APP_API_URL+`/api/projects/create`;
        }
        axios.post(url, project, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('accesToken')
            }
          })
          .then(res => {
            console.log(res);
            console.log(res.data);
            window.location.reload();
        })
        .catch((error) => console.error('Error while attempting to create or update a project:', error));
    }

    return (
        <form onSubmit={handleSubmit}>
            <input type="hidden" value={project.pk}/>
            <div>
                <label>nom du projet</label>
                <input type="text" name="project_name" value={project.name} onChange={(e) => setProject({ ...project, name: e.target.value })} required/>
            </div>
            <div>
                <label> résumé </label>
                <input type="text" name="summary" value={project.summary} onChange={(e) => setProject({ ...project, summary: e.target.value })} required/>
            </div>
            <div>
                <label> bannière </label>
                <div className={Styles.ProjectManagerInputFile}>
                    <input type="file" name="banner"/>
                    {project.banner && validURL(project.banner) && (
                        <div>
                            <img src={project.banner} alt="Project Banner" />
                        </div>
                    )}
                </div>
            </div>
            <div>
                <label> description </label>
                <textarea name="description" value={project.description} onChange={(e) => setProject({ ...project, description: e.target.value })} required></textarea>
            </div>
            <div>
                <label> medium </label>
                <div className={Styles.ProjectManagerInputFile}>
                    <input type="file" name="medium"/>
                    {project.medium && validURL(project.medium) &&  (
                         <Suspense fallback={<div>Loading…</div>}>
                            <Medium url={project.medium} />
                         </Suspense>
                    )}
                </div>
            </div>
            <div>
                <label> technologie </label>
                <select name="technology" value={project.technology} onChange={(e) => setProject({ ...project, technology: e.target.value })} required>
                    <option value=""> -- select an option -- </option>
                    {technologies.map((technology) => 
                        <option key={technology.pk} name={technology.name+" technology"} value={technology.pk}>{technology.name}</option>
                    )}
                </select>
            </div>
            <div className={Styles.ButtonDiv}>
                {project_id !== undefined ? <button type="submit">Modifier</button> : <button type="submit">Créer</button>}
                
            </div>
        </form>
    );
}

export default ProjectManager;