import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Styles from "../css_modules/Slideshow.module.css";
import Banner from "../common/Banner";
import { interleaveArrays } from "../Utils";

function Slideshow(){
    const [projects, setProjects] = useState([]);
    const [articles, setArticles] = useState([]);
    let slides = [];

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await fetch(process.env.REACT_APP_API_URL+'/api/projects/last_projects');
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setProjects(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
        
          fetchData();
      }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await fetch(process.env.REACT_APP_API_URL+'/api/articles/last_articles');
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setArticles(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
        
          fetchData();
      }, []);

    slides = interleaveArrays(projects, articles);

    let result_lenght = slides.length
    if (result_lenght == 0) {
        return (
            <div className={Styles.slide}>
                <div className={Styles.banner}>
                    <Banner />
                </div>
                <div className={Styles.informations}>
                    <span>Bienvenue !</span>
                </div>
            </div>
        )

    } else {
        if (result_lenght < 6) {
            for (let i = result_lenght; i < 7; i++) {
                slides.push(slides[i-result_lenght]);
            }
        }
    
        return (
            <div className={Styles.slideShow}>
                <div className={Styles.slideshowContainer}>
                    {slides.map((slide) => (
                        <Slide slide={slide}/>
                    ))}
                </div>
            </div>
        )
    }
}

function Slide({slide}) {
    if (slide !== undefined && slide.project_uuid !== undefined) {
        return (
            <div className={Styles.slide}>
                <Link to={"/project/"+slide.project_uuid}>
                    <div className={Styles.banner}>
                        <Banner url={slide.banner} />
                    </div>
                    <div className={Styles.informations}>
                        <span>{slide.summary}</span>
                    </div>
                </Link>
            </div>
        );
    } else if(slide !== undefined && slide.article_uuid !== undefined){
        return (
            <div className={Styles.slide}>
                <Link to={"/blog/"+slide.article_uuid}>
                    <div className={Styles.banner}>
                        <Banner url={slide.banner} />
                    </div>
                    <div className={Styles.informations}>
                        <span>{slide.summary}</span>
                    </div>
                </Link>
            </div>
        );
    }
}

export default Slideshow;