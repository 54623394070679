import React from 'react';
import defaultBannerImage from "../images/defaultBannerImage.png"

function Banner({url}) {
    function isImage(url) {
        if (url !== undefined && url !== null)
            return (url.match(/\.(jpeg|jpg|gif|png)$/i) !== null);
        else
            return false;
    }

    if(isImage(url)) {
        return (
                <img src={url} alt="Project Banner" />
        );
    } else {
        return (
            <img src={defaultBannerImage} alt="Project Banner" />
        );
    }
}

export default Banner;