import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { SiteSideContext } from '../contexts/SiteSideContext';
import { PageContext } from '../contexts/PageContext';

function Blog() {
    const {setCurrentPage} = useContext(PageContext);
    const {setCurrentSiteSide} = useContext(SiteSideContext);
    setCurrentSiteSide("home");
    setCurrentPage("blog");

    return (
            <Outlet/>
    )
}

export default Blog;