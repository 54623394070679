import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Styles from "../css_modules/Menu.module.css"
import { PageContext } from '../contexts/PageContext';
import { SiteSideContext } from '../contexts/SiteSideContext';
import { UserContext } from '../contexts/UserContext';
import { TokenContext } from '../contexts/TokenContext';
import Popup from "../common/Popup.js"
import { validUserToken } from '../Utils.js'

function Menu({pagesLinks}) {
    const {setCurrentPage} = useContext(PageContext);

    return (
        <div className={Styles.menu}>
            <MenuListItems pagesLinks={pagesLinks} />
            <div className={Styles.userConnexion}>
                <AuthenticationManager />
            </div>
        </div>
    )
}

function MenuListItems({pagesLinks}) {
    const {currentPage, setCurrentPage} = useContext(PageContext);
    const {currentSiteSide, setCurrentSiteSide} = useContext(SiteSideContext);
    const {currentUser} = useContext(UserContext);

    function changeCurrentPageSide(page, sideSite) {
        setCurrentPage(page);
        setCurrentSiteSide(sideSite);
    }
    
    return (
        <>
        <ul className={Styles.menuItems}>
            {pagesLinks.map((pageLink) => {
                if (pageLink.key === "administration" && validUserToken(currentUser)) {
                    return (
                        <Link to="/admin" className={pageLink.key === currentPage ? Styles.selected : ''}>
                            <li key={pageLink.key} className={Styles.menuItem} onClick={() => changeCurrentPageSide(pageLink.key, "admin")} >
                                <div className={Styles.textMenuItem}><span>{pageLink.text}</span></div>
                                <div className={Styles.iconMenuItem}><span>{pageLink.icon}</span></div>
                            </li>
                        </Link>
                    )
                }
                else if (pageLink.key === "home") {
                    return (
                        <Link to="/" className={pageLink.key === currentPage ? Styles.selected : ''}>
                            <li key={pageLink.key} className={Styles.menuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")} >
                                <div className={Styles.textMenuItem}><span>{pageLink.text}</span></div>
                                <div className={Styles.iconMenuItem}><span>{pageLink.icon}</span></div>
                            </li>
                        </Link>
                    )
                }
                else if (currentSiteSide === "home" && pageLink.key !== "administration") {
                    return (
                        <Link to={pageLink.key} className={pageLink.key === currentPage ? Styles.selected : ''}>
                            <li key={pageLink.key} className={Styles.menuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")} >
                                <div className={Styles.textMenuItem}><span>{pageLink.text}</span></div>
                                <div className={Styles.iconMenuItem}><span>{pageLink.icon}</span></div>
                            </li>
                        </Link>
                    )
                }
                else if (currentSiteSide === "admin" && pageLink.key !== "home") {
                    return (
                        <Link to={"/admin/"+pageLink.key} className={pageLink.key === currentPage ? Styles.selected : ''}>
                            <li key={pageLink.key} className={Styles.menuItem} onClick={() => changeCurrentPageSide(pageLink.key, "admin")} >
                                <div className={Styles.textMenuItem}><span>{pageLink.text}</span></div>
                                <div className={Styles.iconMenuItem}><span>{pageLink.icon}</span></div>
                            </li>
                        </Link>
                    )
                }
            })}
        </ul>
        </>

    );
}

function AuthenticationManager () {
    const {currentUser} = useContext(UserContext);
    const {logoutUser} = useContext(TokenContext);
    const [disconnectPopupOpened, setDisconnectPopupOpened] = useState(false);

    function disconnect() {
        logoutUser();
        setDisconnectPopupOpened(false);
    }

    function closePopup() {
        setDisconnectPopupOpened(false);
    }

    if (!validUserToken(currentUser)){
        return (
            <div className={Styles.authenticationDiv}>
                <Link to="/authentication"><FontAwesomeIcon icon={faUser} /></Link>
            </div>
        );
    } else {
        return ( 
            <>
                <div className={Styles.authenticationDiv} onClick={() => setDisconnectPopupOpened(true)}>
                    <FontAwesomeIcon icon={faUser}/>
                </div>
                {disconnectPopupOpened ?           
                    <Popup 
                        title={"Voulez vous vous déconnecter ?"} 
                        validText="Déconnexion" 
                        cancelText="Annuler" 
                        callbackValid={() => disconnect()} 
                        callbackCancel={() => closePopup()}
                    />
                    : null
                }
            </>
        );
    }
}

export default Menu;