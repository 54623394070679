import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Styles from "../css_modules/ProjectListAdmin.module.css"
import Popup from "../common/Popup.js"

function AdminProjectList() {
    let navigate = useNavigate();
    const [deletePopupOpened, setDeletePopupOpened] = useState(false);
    const [projectToDelete, setDeleteprojectToDelete] = useState(false);

    function closePopup() {
        setDeletePopupOpened(false);
        setDeleteprojectToDelete(undefined);
    }

    function deleteProject(project_id){
        axios.delete(process.env.REACT_APP_API_URL+`/api/projects/delete/`+project_id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('accesToken')
            }
          })
        .then(response => {
            console.log('Resource deleted successfully:', response.data);
            window.location.reload();
        })
        .catch(error => {
            console.error('Error deleting resource:', error);
        });
        closePopup();
    }


    return (
        <div className={Styles.projectListBody}>
            <h1>Liste des projets</h1>
            <ProjectListItems setDeletePopupOpened={setDeletePopupOpened} setDeleteprojectToDelete={setDeleteprojectToDelete}/>
            <button onClick={() => navigate("/admin/project/create")}>Ajouter</button>
            { deletePopupOpened 
                ? <Popup 
                    title={"Suppression du projet "+projectToDelete.name+" ?"} 
                    validText="Supprimer" 
                    cancelText="Annuler" 
                    callbackValid={() => deleteProject(projectToDelete.pk)} 
                    callbackCancel={() => closePopup()}
                    />
                : null
            }
        </div>
    )
}

function ProjectListItems({setDeletePopupOpened, setDeleteprojectToDelete}) {
    const [projects, setProjects] = useState([]);

    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accesToken'),
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await fetch(process.env.REACT_APP_API_URL+'/api/projects/admin_list/', requestOptions);
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setProjects(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
        
          fetchData();
    }, []);

    return (
          <ul className={Styles.projectList}>
            {projects.map((project) => (
                <ProjectListItem project={project} setDeletePopupOpened={setDeletePopupOpened} setDeleteprojectToDelete={setDeleteprojectToDelete}/>
            ))}
          </ul>
    );
}

function ProjectListItem({project,setDeletePopupOpened, setDeleteprojectToDelete}) {

    function openPopup(project) {
        setDeletePopupOpened(true);
        setDeleteprojectToDelete(project);
    }

    return (
                        
        <li key={project.pk} className={Styles.project}>
            <Link to={"/admin/project/"+project.pk}>{project.name}</Link>
            <FontAwesomeIcon icon={faCircleXmark} onClick={() => openPopup(project)}/>
        </li>
    );
}

export default AdminProjectList;