import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import Styles from "../css_modules/Home.module.css"
import { PageContext } from '../contexts/PageContext';
import { SiteSideContext } from '../contexts/SiteSideContext';
import { UserContext } from '../contexts/UserContext';
import Slideshow from "./Slideshow";
import { validUserToken } from '../Utils.js'

function Home({pagesLinks}) {
    console.log(process.env.REACT_APP_ENV);
    return (
        <div className={Styles.homeBody}>
            <h1>Bienvenue sur le site web Darktouffe</h1>
            <Slideshow />
            <HomeListItems pagesLinks={pagesLinks}/>
        </div>
    )
}

function HomeListItems({pagesLinks}) {
    const {setCurrentPage} = useContext(PageContext);
    const {setCurrentSiteSide} = useContext(SiteSideContext);
    const {currentUser} = useContext(UserContext);

    function changeCurrentPageSide(page, sideSite) {
        setCurrentPage(page);
        setCurrentSiteSide(sideSite);
    }

    return (
        <ul className={Styles.bodyMenu}>
            {pagesLinks.map((pageLink) => {
                if (pageLink.key === "administration" && validUserToken(currentUser)) {
                    return (
                        
                            <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "admin")}>
                                {pageLink.icon}
                                <Link to="/admin"><span>{pageLink.text}</span></Link>
                            </li>
                    )
                } else if (pageLink.key === "project") {
                    return (
                        
                            <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                                {pageLink.icon}
                                <Link to="/project"><span>{pageLink.text}</span></Link>
                            </li>
                    )
                } else if (pageLink.key === "blog") {
                    return (
                        
                            <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                                {pageLink.icon}
                                <Link to="/blog"><span>{pageLink.text}</span></Link>
                            </li>
                    )
                }else if (pageLink.key === "contact") {
                    return (
                        
                            <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                                {pageLink.icon}
                                <Link to="/contact"><span>{pageLink.text}</span></Link>
                            </li>
                    )
                } else if (pageLink.key === "about") {
                    return (
                        
                            <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                                {pageLink.icon}
                                <Link to="/about"><span>{pageLink.text}</span></Link>
                            </li>
                    )
                } else if (pageLink.key !== "home" && pageLink.key !== "administration" && pageLink.key !== "project" && pageLink.key !== "blog") {
                    return (
                        <li key={pageLink.key} className={Styles.bodyMenuItem} onClick={() => changeCurrentPageSide(pageLink.key, "home")}>
                            {pageLink.icon}
                            <Link to="/admin"><span>{pageLink.text}</span></Link>
                        </li>
                    )
                }
            })}
        </ul>
    );
}

export default Home;