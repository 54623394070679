import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Styles from "../css_modules/ArticleListAdmin.module.css"
import Popup from "../common/Popup.js"

function AdminArticleList() {
    let navigate = useNavigate();
    const [deletePopupOpened, setDeletePopupOpened] = useState(false);
    const [articleToDelete, setDeletearticleToDelete] = useState(false);

    function closePopup() {
        setDeletePopupOpened(false);
        setDeletearticleToDelete(undefined);
    }

    function deleteArticle(article_id){
        axios.delete(process.env.REACT_APP_API_URL+`/api/articles/delete/`+article_id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('accesToken')
            }
          })
        .then(response => {
            console.log('Resource deleted successfully:', response.data);
            window.location.reload();
        })
        .catch(error => {
            console.error('Error deleting resource:', error);
        });
        closePopup();
    }


    return (
        <div className={Styles.articleListBody}>
            <h1>Liste des articles</h1>
            <ArticleListItems setDeletePopupOpened={setDeletePopupOpened} setDeletearticleToDelete={setDeletearticleToDelete}/>
            <button onClick={() => navigate("/admin/article/create")}>Ajouter</button>
            { deletePopupOpened 
                ? <Popup 
                    title={"Suppression de l'article "+articleToDelete.name+" ?"} 
                    validText="Supprimer" 
                    cancelText="Annuler" 
                    callbackValid={() => deleteArticle(articleToDelete.pk)} 
                    callbackCancel={() => closePopup()}
                    />
                : null
            }
        </div>
    )
}

function ArticleListItems({setDeletePopupOpened, setDeletearticleToDelete}) {
    const [articles, setArticles] = useState([]);

    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accesToken'),
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await fetch(process.env.REACT_APP_API_URL+'/api/articles/admin_list/', requestOptions);
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setArticles(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
        
          fetchData();
    }, []);

    return (
          <ul className={Styles.articleList}>
            {articles.map((article) => (
                <ArticleListItem article={article} setDeletePopupOpened={setDeletePopupOpened} setDeletearticleToDelete={setDeletearticleToDelete}/>
            ))}
          </ul>
    );
}

function ArticleListItem({article,setDeletePopupOpened, setDeletearticleToDelete}) {

    function openPopup(article) {
        setDeletePopupOpened(true);
        setDeletearticleToDelete(article);
    }

    return (
                        
        <li key={article.pk} className={Styles.article}>
            <Link to={"/admin/article/"+article.pk}>{article.name}</Link>
            <FontAwesomeIcon icon={faCircleXmark} onClick={() => openPopup(article)}/>
        </li>
    );
}

export default AdminArticleList;