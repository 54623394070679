export function validURL(str) {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlPattern.test(str);
}

export function validUserToken(user) {
  let currentDate = new Date();
  if(user === null)
    return false;
  if (user.exp * 1000 < currentDate.getTime())
    return false;
  return true;
}

export function interleaveArrays(...arrays) {
  const maxLength = Math.max(...arrays.map(arr => arr.length));
  const result = [];

  for (let i = 0; i < maxLength; i++) {
      for (const array of arrays) {
          if (i < array.length) {
              result.push(array[i]);
          }
      }
  }

  return result;
}
