import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { PageContext } from '../contexts/PageContext';

function AdminMedia() {
    const {setCurrentPage} = useContext(PageContext);
    setCurrentPage("media");
    return (
            <Outlet/>
    )
}

export default AdminMedia;