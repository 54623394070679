import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faExpand } from '@fortawesome/free-solid-svg-icons';
import Styles from "../css_modules/MediumListAdmin.module.css"
import Popup from "../common/Popup.js"
import Banner from "../common/Banner.js"

function AdminMediumList() {
    let navigate = useNavigate();
    const [deletePopupOpened, setDeletePopupOpened] = useState(false);
    const [mediumToDelete, setDeleteMediumToDelete] = useState(false);

    function closePopup() {
        setDeletePopupOpened(false);
        setDeleteMediumToDelete(undefined);
    }

    function deleteMedium(medium_id){
        axios.delete(process.env.REACT_APP_API_URL+`/api/media/delete/`+medium_id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('accesToken')
            }
          })
        .then(response => {
            console.log('Resource deleted successfully:', response.data);
            window.location.reload();
        })
        .catch(error => {
            console.error('Error deleting resource:', error);
        });
        closePopup();
    }


    return (
        <div className={Styles.mediumListBody}>
            <h1>Liste des media</h1>
            <MediumListItems setDeletePopupOpened={setDeletePopupOpened} setDeleteMediumToDelete={setDeleteMediumToDelete}/>
            <button onClick={() => navigate("/admin/media/create")}>Ajouter</button>
            { deletePopupOpened 
                ? <Popup 
                    title={"Suppression du medium "+mediumToDelete.name+" ?"} 
                    validText="Supprimer" 
                    cancelText="Annuler" 
                    callbackValid={() => deleteMedium(mediumToDelete.pk)} 
                    callbackCancel={() => closePopup()}
                    />
                : null
            }
        </div>
    )
}

function MediumListItems({setDeletePopupOpened, setDeleteMediumToDelete}) {
    const [medium, setMedium] = useState([]);

    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accesToken'),
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL+'/api/media/list/', requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setMedium(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
        
          fetchData();
    }, []);

    return (
          <ul className={Styles.mediumList}>
            {medium.map((medium) => (
                <MediumListItem medium={medium} setDeletePopupOpened={setDeletePopupOpened} setDeleteMediumToDelete={setDeleteMediumToDelete}/>
            ))}
          </ul>
    );
}

function MediumListItem({medium, setDeletePopupOpened, setDeleteMediumToDelete}) {

    function openPopup(medium) {
        setDeletePopupOpened(true);
        setDeleteMediumToDelete(medium);
    }

    function showMediumInTab(medium) {
        window.open(medium.medium, '_blank');
    }

    return (               
        <li key={medium.pk} className={Styles.medium}>
            <Link to={"/admin/media/"+medium.pk}>
                <div className={Styles.banner}>
                    <Banner url={medium.medium} />
                </div>
                <div className={Styles.informations}>
                    <span>{medium.name}</span>
                </div>
            </Link>
            <div className={Styles.action}>
                <FontAwesomeIcon icon={faCircleXmark} className={Styles.redIcon} onClick={() => openPopup(medium)}/>
                <FontAwesomeIcon icon={faExpand} className={Styles.blankIcon} onClick={() => showMediumInTab(medium)}/>
            </div>
        </li>
    );
}

export default AdminMediumList;