import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {validURL} from '../Utils.js'
import Styles from "../css_modules/MediumManager.module.css"

const Medium = lazy(() => import('../common/Medium.js'));
function MediumManager() {
    let params = useParams()
    if (Object.keys(params).length === 0){
        return (
            <div className={Styles.MediumManagerBody}>
                <h1>Ajouter un medium</h1>
                <MediumForm/>
            </div>
        );
    }
    else {
        return (
            <div className={Styles.MediumManagerBody}>
                <h1>Modifier le medium</h1>
                <MediumForm medium_id={params.idMedium}/>
            </div>
            
        );
    }
}

function MediumForm({medium_id}) {
    const [medium, setMedium] = useState({
        pk:"",
        medium: "",
        name: "",
        alternative_text: "",
        description: "",
        type: "",
        author: ""
      });

    const requestOptions = {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accesToken'),
        }
    };

    useEffect(() => {
        if (medium_id !== undefined) {
            fetch(process.env.REACT_APP_API_URL+'/api/media/' + medium_id, requestOptions)
            .then((response) => response.json())
            .then((data) => setMedium(data[0]))
            .catch((error) => console.error('Error fetching data:', error));
        }
        else {
            setMedium({
                pk:"",
                medium: "",
                name: "",
                alternative_text: "",
                description: "",
                type: "",
                author: ""
            });
        }
    }, [medium_id]);

    function handleSubmit(event){
        event.preventDefault();

        // Get form media values
        const form = event.target;
        const medium_file = form.elements.medium.files[0]; // For file input

        medium.medium = medium_file !== undefined ? medium_file : "";

        let url = '';
        if (medium_id !== undefined) {
            url = process.env.REACT_APP_API_URL+`/api/media/update`;
        }
        else {
            url = process.env.REACT_APP_API_URL+`/api/media/create`;
        }
        axios.post(url, medium, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('accesToken')
            }
          })
          .then(res => {
            console.log(res);
            console.log(res.data);
            window.location.reload();
        })
        .catch((error) => console.error('Error while attempting to create or update a medium:', error));
    }

    return (
        <form onSubmit={handleSubmit}>
            <input type="hidden" value={medium.pk}/>
            <input type="hidden" value={medium.type}/>
            <div>
                <label> medium </label>
                {/* TODO */}
                
                <div className={Styles.MediumManagerInputFile}>
                    {!medium.medium ? 
                        <div className={Styles.MediumManagerInputFile}>
                            <input type="file" name="medium" required/> 
                        </div>
                        : null
                    }
                    {medium.medium && validURL(medium.medium) &&  (
                        <div className={Styles.MediumManagerInputFile}>
                            <input type="file" name="medium"/>
                            <Suspense fallback={<div>Loading…</div>}>
                            <Medium url={medium.medium} />
                            </Suspense>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <label>nom du medium</label>
                <input type="text" name="medium_name" value={medium.name} onChange={(e) => setMedium({ ...medium, name: e.target.value })} required/>
            </div>
            <div>
                <label> texte alternatif </label>
                <input type="text" name="alternative_text" value={medium.alternative_text} onChange={(e) => setMedium({ ...medium, alternative_text: e.target.value })} required/>
            </div>
            <div>
                <label> description </label>
                <textarea name="description" value={medium.description} onChange={(e) => setMedium({ ...medium, description: e.target.value })} required></textarea>
            </div>
            <div>
                <label> auteur </label>
                <input type="text" name="author" value={medium.author} onChange={(e) => setMedium({ ...medium, author: e.target.value })} required/>
            </div>
            <div className={Styles.ButtonDiv}>
                {medium_id !== undefined ? <button type="submit">Modifier</button> : <button type="submit">Créer</button>}
                
            </div>
        </form>
    );
}

export default MediumManager;