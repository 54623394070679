import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Styles from "../css_modules/ArticleList.module.css"
import Banner from "../common/Banner.js"
import { SiteSideContext } from '../contexts/SiteSideContext';

function AdminArticleList() {
    const {setCurrentSiteSide} = useContext(SiteSideContext);
    setCurrentSiteSide("home");
    
    return (
        <div className={Styles.articleListBody}>
            <h1>Liste des articles</h1>
            <ArticleListItems />
        </div>
    )
}

function ArticleListItems() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await fetch(process.env.REACT_APP_API_URL+'/api/articles/list');
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setArticles(data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
        
          fetchData();
    }, []);

    return (
          <ul className={Styles.articleList}>
            {articles.map((article) => (
                <ArticleListItem article={article}/>
            ))}
          </ul>
    );
}

function ArticleListItem({article}) {
    return (
                        
        <li key={article.article_uuid} className={Styles.article}>
            <Link to={"/blog/"+article.article_uuid}>
                <div className={Styles.banner}>
                    <Banner url={article.banner} />
                </div>
                <div className={Styles.informations}>
                    <span>{article.summary}</span>
                </div>
            </Link>
        </li>
)
}

export default AdminArticleList;